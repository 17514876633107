import styled from "styled-components"

import { IntegrationIcon } from "src/components/Integrations/IntegrationIcon"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton } from "src/ui/Button/MButton"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function DashboardUpdateAirbnbTodo() {
  const { t } = useTranslate()

  const { navigate } = useRouter()

  return (
    <MCard padding={spacing.L} boxShadow={false} border>
      <TopWrapper>
        <LabelWrapper>
          <IntegrationIcon
            size={24}
            icon={
              "https://assets.minut.com/integrations/airbnb/airbnb-icon.svg"
            }
          />

          <MText variant="subtitle">
            {t(langKeys.airbnb_extended_permission_todo_title)}
          </MText>
        </LabelWrapper>

        <MButton
          size="small"
          variant="secondary"
          onClick={() => {
            navigate(Routes.OrgIntegration.location("airbnb"))
          }}
        >
          {t(langKeys.update)}
        </MButton>
      </TopWrapper>

      <MText>{t(langKeys.airbnb_extended_permission_todo_body)}</MText>
    </MCard>
  )
}

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing.M};
`

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.S};
`
