import styled from "styled-components"

import { EventLog } from "src/components/EventLog/EventLog"
import { EVENT_LIST_LIMIT } from "src/data/events/logic/eventConstants"
import { useFetchEvents } from "src/data/events/queries/eventQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function LatestEvents() {
  const { t } = useTranslate()
  const { orgId: organizationId } = useOrganization()

  const fetchEvents = useFetchEvents({ limit: 5, organizationId })

  const events = fetchEvents.data?.events || []

  return (
    <div>
      <MText variant="heading2" marginBottom={spacing.L}>
        {t(langKeys.events_latest)}
      </MText>
      <EventLog events={events} loading={fetchEvents.isLoading} />
      <Footer>
        <InternalLink to={`/events?limit=${EVENT_LIST_LIMIT}&offset=0`}>
          {t(langKeys.events_more)}
        </InternalLink>
      </Footer>
    </div>
  )
}

const Footer = styled.div`
  padding: ${spacing.M} 0;
  text-align: right;
`
